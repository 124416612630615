import React, { useEffect, useMemo, useRef, useState } from 'react';
import Section from '../_Misc/Section';
import FrameworkTable from './components/MembersTable';
import { Button, Dropdown, MenuProps } from 'antd';
import { FrameworkModal } from './components/Modal';
import { v4 } from 'uuid';
import { coreService } from '../../../../services/core/core-service';
import { useToast } from '../../../../hooks/useToast';
import { useFrameworkStore } from '../../../../features/frameworks/useFrameworkStore';
import { Framework } from '../../../../features/frameworks/type';
import {
  BANTQuestions,
  ConflictResolutionQuestions,
  MEDDICQuestions,
  // REACHQuestions,
} from '../../../../features/frameworks/predefined-frameworks';

import Style from './style';
const { Container } = Style;

const FrameworkComponent: React.FC<{ frameworks?: Framework[]; lding: boolean }> = ({
  frameworks,
  lding,
}) => {
  const [loading, setLoading] = useState(lding);
  const [open, setOpen] = useState<string | null>(null);

  const predefinedFramework = useRef<Framework | null>(null);

  const store = useFrameworkStore();
  const { error, success } = useToast();

  const handleSave = async (framework: Framework) => {
    try {
      setLoading(true);
      const f = await coreService.createNewFramework({
        name: framework.name,
        meetingType: framework.meetingType,
        quesitons: framework.questions,
      });
      store.actions.addFramework(f);
      setOpen(null);
      success('Framework created');
    } catch {
      error('Failed to create framework');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (framework: Framework) => {
    try {
      setLoading(true);
      const f = await coreService.updateFramework({
        frameworkId: framework.id,
        name: framework.name,
        meetingType: framework.meetingType,
        quesitons: framework.questions,
      });
      store.actions.removeFramework(framework.id);
      store.actions.addFramework(f);
      setOpen(null);
      success('Framework updated');
    } catch {
      error('Failed to update framework');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await coreService.deleteFramework(id);
      store.actions.removeFramework(id);
      success('Framework deleted');
    } catch {
      error('Failed to delete framework');
    } finally {
      setLoading(false);
    }
  };

  const [framework, action] = useMemo(() => {
    if (predefinedFramework.current) {
      const p = { ...predefinedFramework.current };
      predefinedFramework.current = null;
      return [p, handleSave];
    }

    const framework = store.frameworks.find(f => f.id === open);
    if (framework) {
      return [framework, handleUpdate];
    }

    return [
      {
        id: open,
        name: '',
        meetingType: [],
        questions: [],
        createdAt: new Date().toISOString(),
      } as Framework,
      handleSave,
    ];
  }, [open]);

  const items: MenuProps['items'] = [
    {
      label: 'New Framework',
      key: '0',
      onClick: () => setOpen(v4()),
    },
    {
      label: 'MEDDICC Framework',
      key: '1',
      onClick: () => {
        const id = v4();
        predefinedFramework.current = {
          id: id,
          name: 'MEDDICC Framework',
          meetingType: [],
          questions: MEDDICQuestions(),
          createdAt: new Date().toISOString(),
        };
        setOpen(id);
      },
    },
    {
      label: 'BANT Framework',
      key: '2',
      onClick: () => {
        const id = v4();
        predefinedFramework.current = {
          id: id,
          name: 'BANT Framework',
          meetingType: [],
          questions: BANTQuestions(),
          createdAt: new Date().toISOString(),
        };
        setOpen(id);
      },
    },
    {
      label: 'Conflict Resolution Framework',
      key: '3',
      onClick: () => {
        const id = v4();
        predefinedFramework.current = {
          id: id,
          name: 'Conflict Resolution Framework',
          meetingType: [],
          questions: ConflictResolutionQuestions(),
          createdAt: new Date().toISOString(),
        };
        setOpen(id);
      },
    },
    // {
    //   label: 'REACH Framework',
    //   key: '3',
    //   onClick: () => {
    //     const id = v4();
    //     predefinedFramework.current = {
    //       id: id,
    //       name: 'REACH Framework',
    //       meetingType: [],
    //       questions: REACHQuestions(),
    //       createdAt: new Date().toISOString(),
    //     };
    //     setOpen(id);
    //   },
    // },
  ];

  useEffect(() => {
    store.actions.setFrameworks(frameworks || []);
    setLoading(lding);
  }, [frameworks, lding]);

  return (
    <Section
      title="Insight Frameworks"
      description="Workspace Owners and Admins can create automated AI note-taking frameworks to apply at the Meeting, Account, and Portfolio levels of their workspace."
      // learnMore=" "
    >
      <Container>
        <Dropdown menu={{ items: items }} disabled={!store}>
          <Button style={{ width: '200px', marginTop: '10px' }} type="primary">
            Create Framework
          </Button>
        </Dropdown>
        Create Framework
        <FrameworkTable
          loading={loading}
          data={store?.frameworks || []}
          onEdit={id => setOpen(id)}
          onDelete={id => handleDelete(id)}
        />
      </Container>

      <FrameworkModal
        loading={loading}
        open={open !== null}
        onClose={() => setOpen(null)}
        framework={framework}
        onSave={action}
      />
    </Section>
  );
};

export default FrameworkComponent;
