import { toast } from 'react-toastify';

export const useToast = () => {
  const notify = (text: string, style: React.CSSProperties, delay?: number) => {
    toast(text, {
      position: 'bottom-left',
      autoClose: delay || 1700,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      style: {
        ...style,
        textAlign: 'center',
        fontFamily: 'Cera Pro',
        fontSize: '14px',
        lineHeight: '20px',
      },
      progress: undefined,
      closeButton: false,
    });
  };

  const success = (text: string) => {
    notify(text, {
      fontFamily: 'Cera Pro',
      backgroundColor: 'rgba(47, 187, 137, 1)',
      color: 'rgba(250, 251, 253, 1)',
    });
  };

  const neutral = (text: string) => {
    notify(
      text,
      {
        fontFamily: 'Cera Pro',
        backgroundColor: '#282828',
        color: 'rgba(250, 251, 253, 1)',
      },
      20000,
    );
  };

  const error = (text: string) => {
    notify(text, {
      fontFamily: 'Cera Pro',
      backgroundColor: 'rgba(238, 78, 78, 1)',
      color: 'rgba(250, 251, 253, 1)',
    });
  };

  const info = (text: string) => {
    notify(text, {
      fontFamily: 'Cera Pro',
      backgroundColor: 'rgba(250, 251, 253, 1)',
      color: 'black',
    });
  };

  return { success, error, info, neutral };
};
