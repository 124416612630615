import { produce } from 'immer';
import { create } from 'zustand';

export interface Member {
  id: string;
  fullName: string;
  selected: boolean;
}
export interface Industry {
  id: string;
}
export interface Priority {
  id: string;
}
export interface LifecycleStage {
  id: string;
}

export type Selectable<T extends object> = T & { selected: boolean };

interface CustomerIntelligenceFiltersStore {
  category: string;
  dateRange: [null, null] | [Date, Date];
  members: Member[];
  meetingsImInvitedOnly: boolean;
  accounts: {
    id: string;
    name: string;
    provider: string;
  }[];
  industries: Selectable<Industry>[];
  lifecycleStages: Selectable<LifecycleStage>[];
  priorities: Selectable<Priority>[];
  contractRenewalDateBefore: Date | null;
  contractValueMin: number | null;
  contractValueMax: number | null;
  group: string | null;
  subGroup: string | null;
  crmFilters: Map<string, string[]>;
}

const customerIntelligenceFiltersStore = create<CustomerIntelligenceFiltersStore>(() => {
  return {
    category: 'Risks',
    dateRange: [null, null],
    members: [],
    meetingsImInvitedOnly: false,
    accounts: [],
    industries: [],
    priorities: [],
    lifecycleStages: [],
    contractRenewalDateBefore: null,
    contractValueMax: null,
    contractValueMin: null,
    group: null,
    subGroup: null,
    crmFilters: new Map(),
  };
});

const meetingsImInvitedOnlyToggled = (value: boolean) => {
  customerIntelligenceFiltersStore.setState(() => ({ meetingsImInvitedOnly: value }));
};

const categoryChanged = (category: string) =>
  customerIntelligenceFiltersStore.setState(() => ({ category }));

const setCrmMap = (crmFilters: Map<string, string[]>) =>
  customerIntelligenceFiltersStore.setState(() => ({ crmFilters }));

const dateRangeChanged = (dateRange: [Date, Date] | [null, null]) =>
  customerIntelligenceFiltersStore.setState(() => ({ dateRange }));

const memberSelectionToggled = (memberId: string) => {
  customerIntelligenceFiltersStore.setState(state =>
    produce(state, draft => {
      draft.members = draft.members.map(member => {
        if (member.id === memberId) {
          member.selected = !member.selected;
        }
        return member;
      });
    }),
  );
};

const allMembersToggled = (selected: boolean) => {
  customerIntelligenceFiltersStore.setState(state =>
    produce(state, draft => {
      draft.members = draft.members.map(member => {
        member.selected = selected;
        return member;
      });
    }),
  );
};

const accountsSelected = (accounts: { id: string; provider: string; name: string }[]) => {
  customerIntelligenceFiltersStore.setState(state => ({ accounts }));
};

const groupSelected = (group: string) => {
  customerIntelligenceFiltersStore.setState(() => ({ group }));
};

const contractRenewalDateBeforeChanged = (contractRenewalDateBefore: Date | null) => {
  customerIntelligenceFiltersStore.setState(() => ({ contractRenewalDateBefore }));
};

const contractValuesChanged = (minValue: number | null, maxValue: number | null) => {
  customerIntelligenceFiltersStore.setState(() => ({
    contractValueMin: minValue,
    contractValueMax: maxValue,
  }));
};

const filtersLoaded = (
  industries: string[],
  lifecycleStages: string[],
  priorities: string[],
  members: { id: string; fullName: string }[],
) => {
  const {
    lifecycleStages: lsStore,
    priorities: pStore,
    members: mStore,
    industries: iStore,
  } = customerIntelligenceFiltersStore.getState();

  const mappedIndustries = ['(No value set)', ...industries].map(id => {
    const exists = iStore.find(l => l.id === id);
    return {
      id,
      selected: exists ? exists.selected : true,
    };
  });

  const mappedLifcycle = ['(No value set)', ...lifecycleStages].map(id => {
    const exists = lsStore.find(l => l.id === id);
    return {
      id,
      selected: exists ? exists.selected : true,
    };
  });

  const mappedPriorities = ['(No value set)', ...priorities].map(id => {
    const exists = pStore.find(l => l.id === id);
    return {
      id,
      selected: exists ? exists.selected : true,
    };
  });

  const mappedMembers = members.map(mem => {
    const exists = mStore.find(l => l.id === mem.id);
    return {
      id: mem.id,
      fullName: mem.fullName,
      selected: exists ? exists.selected : true,
    };
  });

  customerIntelligenceFiltersStore.setState(() => ({
    industries: mappedIndustries,
    lifecycleStages: mappedLifcycle,
    priorities: mappedPriorities,
    members: mappedMembers,
  }));
};

const filtersReset = () => {
  const {
    lifecycleStages: lsStore,
    priorities: pStore,
    members: mStore,
    industries: iStore,
  } = customerIntelligenceFiltersStore.getState();
  customerIntelligenceFiltersStore.setState(() => ({
    members: mStore.map(ls => ({ ...ls, selected: true })),
    meetingsImInvitedOnly: false,
    accounts: [],
    industries: iStore.map(ls => ({ ...ls, selected: true })),
    lifecycleStages: lsStore.map(ls => ({ ...ls, selected: true })),
    priorities: pStore.map(ls => ({ ...ls, selected: true })),
    contractRenewalDateBefore: null,
    contractValueMin: null,
    contractValueMax: null,
    crmFilters: new Map(),
  }));
};

const reducers = {
  accountsSelected,
  categoryChanged,
  dateRangeChanged,
  groupSelected,
  contractRenewalDateBeforeChanged,
  contractValuesChanged,
  filtersLoaded,
  meetingsImInvitedOnlyToggled,
  allMembersToggled,
  filtersReset,
  memberSelectionToggled,
  setCrmMap,
};

export { reducers, customerIntelligenceFiltersStore, CustomerIntelligenceFiltersStore };

// const prioritiesToggled = (priorityId: string[]) => {
//   customerIntelligenceFiltersStore.setState(state =>
//     produce(state, draft => {
//       draft.priorities = draft.priorities.map(priority => {
//         priority.selected = priorityId.includes(priority.id);
//         return priority;
//       });
//     }),
//   );
// };

// const industriesToggled = (industryId: string[]) => {
//   customerIntelligenceFiltersStore.setState(state =>
//     produce(state, draft => {
//       draft.industries = draft.industries.map(industry => {
//         industry.selected = industryId.includes(industry.id);
//         return industry;
//       });
//     }),
//   );
// };

// const lifecycleStagesToggled = (lifecycleStageId: string[]) => {
//   customerIntelligenceFiltersStore.setState(state =>
//     produce(state, draft => {
//       draft.lifecycleStages = draft.lifecycleStages.map(stage => {
//         stage.selected = lifecycleStageId.includes(stage.id);
//         return stage;
//       });
//     }),
//   );
// };

// const subGroupSelected = (subGroup: string) => {
//   customerIntelligenceFiltersStore.setState(() => ({ subGroup }));
// };
