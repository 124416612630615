import React, { ReactElement, useEffect, useState } from 'react';
import { useCrmFilterValues } from '../../../../features/crm-integration/hooks/use-crm-filter-values';
import { useCrmIntegration } from '../../../../features/crm-integration/hooks/use-crm-integration';
import { Select } from 'antd';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';

export const CRMFilters: React.FC = () => {
  const { filters: crmFilters } = useCrmFilterValues();
  const { workspaceCrmIntegration } = useCrmIntegration();
  const { setCrmMap } = useCustomerIntelligenceFilters();

  const [values, setValues] = useState<Map<string, string[]>>(new Map());
  const [components, setComponents] = useState<ReactElement[]>([]);

  useEffect(() => {
    setComponents([]);

    const components: ReactElement[] = [];
    for (const filt in crmFilters) {
      const label = workspaceCrmIntegration
        ? workspaceCrmIntegration.workspace.properties.find(p => p.id === filt)?.label || filt
        : filt;
      components.push(
        <Select
          style={{ width: '220px', maxHeight: '32px' }}
          placeholder={label}
          options={crmFilters[filt].map(opt => ({ label: opt, value: opt }))}
          onChange={value => {
            setValues(new Map(values).set(filt, value));
          }}
          mode="multiple"
        />,
      );

      setComponents(components);
    }
  }, [workspaceCrmIntegration, crmFilters, values]);

  useEffect(() => setCrmMap(values), [values]);

  return <>{components.map(m => m)}</>;
};
