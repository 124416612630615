import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/selectors';
import { Content } from './style';
import { CardHeader } from '../../onboarding/Steps/CardHeader';
import { useRedirectNavigation } from '../../../features/redirectNavigation/useRedirectNavigation';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../../theme/colors';
import { Button, Spin } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { coreAPI } from '../../../API/core';
import { Invitation } from '../../../redux/typings/organizations';
import { coreService } from '../../../services/core/core-service';
import { FramedLayout } from '../../../components/layout/FramedLayout/FramedLayout';

export const MergeInvitationPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [invitation, setInvitation] = useState<Invitation | null>(null);

  const { id } = useParams();
  const redirect = useRedirectNavigation();
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const { isFetching } = useQuery({
    queryKey: ['merge-join-invitation'],
    queryFn: async () => {
      const coreService = new coreAPI();
      const invitations = await coreService.fetchInvitations();

      invitations.forEach(i => {
        if (i.id === id) setInvitation(i);
      });

      return invitations;
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (!user) {
      redirect.setRedirect();
      navigate('/signin');
    }
  }, []);

  if ((!invitation && !isFetching) || !id) {
    navigate('/?notification=No invitations to join workspace found.');
  }

  return (
    <FramedLayout logOut>
      <Content style={{ alignItems: 'center' }}>
        {isFetching ? (
          <Spin />
        ) : (
          <>
            <CardHeader title="Join a new workspace" subtitle="" />
            <p style={{ marginTop: '50px' }}>
              You have been invited by{' '}
              <span style={{ color: colors.primary[600] }}>
                {invitation?.invitedBy?.firstName} {invitation?.invitedBy?.lastName}
              </span>{' '}
              to join the{' '}
              <span style={{ color: colors.primary[600] }}>{invitation?.organization.name}</span>{' '}
              workspace on UpdateAI.
            </p>

            <p
              style={{
                backgroundColor: colors.primary[100],
                padding: '10px',
                borderRadius: '8px',
              }}
            >
              Important: Upon accepting this invitation and signing into UpdateAI, your meetings and
              account will automatically be moved to the{' '}
              <span style={{ color: colors.primary[600] }}>{invitation?.organization.name}</span>{' '}
              workspace.
              <br></br>
              <br></br>
              <b>You will still have control over the privacy of your own meetings.</b>
            </p>

            <Button
              type="primary"
              style={{ width: '400px', marginTop: '50px', justifySelf: 'center' }}
              loading={loading}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                if (id) {
                  try {
                    await coreService.acceptInvitation(id);
                    navigate(
                      '/?notification=Welcome to your new workspace! Your past meetings have been migrated.',
                    );
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } catch (e: any) {
                    navigate(
                      '/?notification=Please contact concierge@update.ai an error ocurried while migrating your meetings.',
                    );
                    console.log(e);
                  } finally {
                    setLoading(false);
                  }
                }
              }}
            >
              Accept Invitation to Join {invitation?.organization.name}
            </Button>
          </>
        )}
      </Content>
    </FramedLayout>
  );
};
