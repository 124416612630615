import React from 'react';
import { BoundedContent, Bullet, Item, Content } from '../style';
import { ItemContent } from './style';
import { FramedLayout } from '../../../../components/layout/FramedLayout/FramedLayout';
import { TwoColumnLayout } from '../../../../components/layout/FramedLayout/TwoColumnLayout';

interface Props {
  children: React.ReactNode;
}

export const ZoomExtraInfo: React.FC<Props> = ({ children }) => {
  return (
    <FramedLayout error={undefined}>
      <TwoColumnLayout
        title="How we capture your meetings"
        image="https://assets.update.ai/native_cloud.png"
        type="secondary"
        styles={{
          height: 'auto',
          width: 'auto',
        }}
      >
        <Content>
          <BoundedContent style={{ marginTop: '30px', width: '450px' }}>
            <Item>
              <Bullet>1</Bullet>
              <ItemContent>
                As long as your Zoom account is connected, we’ll trigger{' '}
                <span>Zoom’s native cloud recording to capture meetings that you host.</span>
              </ItemContent>
            </Item>

            <Item>
              <Bullet>2</Bullet>
              <ItemContent>
                The <span>UpdateAI Meetings Assistant</span> (a.k.a. “bot”) will join meeting where
                you are <span>not</span> the host.
              </ItemContent>
            </Item>

            <Item>
              <Bullet>3</Bullet>
              <ItemContent>
                The bot will also join any meetings you have on <span>Google Meet</span> or{' '}
                <span>Microsoft Teams</span>.
              </ItemContent>
            </Item>
          </BoundedContent>

          {children}
        </Content>
      </TwoColumnLayout>
    </FramedLayout>
  );
};
