/* eslint-disable @typescript-eslint/no-explicit-any */

export const ONBOARDING_TOTAL_STEPS_AMOUNT = 5;

export enum OnboardingStatus {
  Validation = 'VALIDATION',
  SelectOrganization = 'SELECT_ORGANIZATION',
  CalendarSync = 'CALENDAR_SYNC',
  ConferencingSync = 'CONFERENCING_SYNC',
  Completed = 'COMPLETED',
  RecordingSettings = 'RECORDING_SETTINGS',
}

export enum EventStatus {
  READY = 'READY',
  FINISHED = 'FINISHED',
  SKIPPED = 'SKIPPED',
  SCHEDULED = 'SCHEDULED',
  DID_NOT_START = 'DID_NOT_START',
  LIMITED = 'LIMITED',
  NOT_AVAILABLE_BASIC = 'NOT_AVAILABLE_BASIC',
  BASIC = 'BASIC',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LIVE = 'LIVE',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING',
}
