import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../../componentsV2/Modal';
import { Alert } from '../../../../../components/alerts/Alert';
import { Table } from '../../../../../componentsV2/Table';
import { Button, Input, Tooltip } from 'antd';
import { StoredFilterType } from '../types';
import { DeleteOutlined } from '@ant-design/icons';
import Styles from './styles';
import { KeyToFilterStore } from '../../../../../features/shared/dashboard-filters/types';

const { Container } = Styles;

interface Props {
  open: boolean;
  data: StoredFilterType[];
  loading?: boolean;
  removeFilter(id: string): void;
  updateFilter(id: string, args: { name?: string; config?: Partial<KeyToFilterStore> }): void;
  onClose: () => void;
}

export const ManageFiltersModal: React.FC<Props> = ({
  open,
  data,
  loading = false,
  onClose,
  removeFilter,
  updateFilter,
}) => {
  const [openFilter, setOpenFilter] = useState<StoredFilterType | undefined>(undefined);
  const [removedFilter, setRemovedFilter] = useState<string | undefined>(undefined);

  const handleCancel = () => {
    if (!loading) {
      onClose();
      setOpenFilter(undefined);
    }
  };

  const labels = {
    title: 'Manage Views',
    close: 'Close Views',
    info: 'Here you can edit the name of the Views or remove them.',
  };

  return (
    <Modal
      width={600}
      open={open}
      secondaryAction={{
        label: labels.close,
        onClick: handleCancel,
        disabled: loading,
      }}
      title={labels.title}
      onClose={handleCancel}
    >
      <Container>
        <Alert title="" variant="info" message={labels.info} />

        <Table
          data={data}
          pagination={false}
          columns={[
            {
              title: 'name',
              key: 'name',
              render: (value: StoredFilterType, record, index) => {
                if (openFilter?.id === value.id) {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Input
                        style={{ width: '200px' }}
                        value={openFilter.name}
                        onChange={e => {
                          setOpenFilter({ ...openFilter, name: e.target.value });
                        }}
                      />
                      <Button
                        type="primary"
                        onClick={() => {
                          updateFilter(value.id, { name: openFilter.name });
                          setOpenFilter(undefined);
                        }}
                        loading={loading}
                        disabled={loading}
                      >
                        Save
                      </Button>
                      <Button onClick={() => setOpenFilter(undefined)} disabled={loading}>
                        Cancel
                      </Button>
                    </div>
                  );
                } else {
                  return (
                    <Tooltip title={value.name}>
                      <div
                        style={{
                          cursor: 'pointer',
                          width: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => setOpenFilter(value)}
                      >
                        {value.name}
                      </div>
                    </Tooltip>
                  );
                }
              },
            },
            {
              title: '',
              key: 'actions',
              render: (value: StoredFilterType, record, index) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <Button
                      onClick={() => {
                        removeFilter(value.id);
                        setRemovedFilter(value.id);
                      }}
                      loading={removedFilter === value.id}
                      disabled={loading}
                    >
                      <DeleteOutlined />
                    </Button>
                  </div>
                );
              },
            },
          ]}
        />
      </Container>
    </Modal>
  );
};
