import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Space } from 'antd';
import { useEffect, useState } from 'react';
import Styles from './styles';

const { OptionsContainer, SelectAllHeader, Contents, OptionLabel } = Styles;

interface Options {
  id: string;
  label: string;
  selected: boolean;
}

interface Props {
  label: string;
  value?: string[];
  onSelectionChanged: (values: string[]) => unknown;
  showAll?: boolean;
  style?: React.CSSProperties;
  options: { id: string; label: string }[];
}

export const SelectAll: React.FC<Props> = ({
  value = [],
  showAll = false,
  options,
  label,
  style = {},
  onSelectionChanged,
}) => {
  const [currentOptions, setCurrentOptions] = useState<Options[]>([]);

  useEffect(() => {
    const newOptions: Options[] = options.map(opt => {
      return {
        ...opt,
        selected: value.includes(opt.id),
      };
    });

    setCurrentOptions(newOptions);
  }, [options, value]);

  const optionsCount = currentOptions.length;
  const selectedCount = currentOptions.filter(o => o.selected).length;
  const hasOptions = optionsCount > 0;
  const allSelected = optionsCount === selectedCount;
  const buttonLabel = hasOptions
    ? allSelected
      ? `${label} (All)`
      : `${label} (${selectedCount})`
    : `${label} (None)`;

  const handleSelectionChanged = (id: string, checked: boolean) => {
    const newValues = currentOptions
      .map(o => {
        if (o.id === id) {
          o.selected = checked;
        }
        return o;
      })
      .filter(o => o.selected)
      .map(o => o.id);

    onSelectionChanged(newValues);
  };

  const handleSelectAll = () => {
    const allOptionsId = options.map(o => o.id);
    onSelectionChanged(allSelected ? [] : allOptionsId);
  };

  return (
    <Dropdown
      dropdownRender={() => (
        <Contents>
          {showAll && (
            <SelectAllHeader>
              <OptionLabel onClick={handleSelectAll}>
                {allSelected ? 'Unselect All' : 'Select All'}
              </OptionLabel>
            </SelectAllHeader>
          )}

          <OptionsContainer>
            {currentOptions.map(option => {
              return (
                <Space onClick={() => handleSelectionChanged(option.id, !option.selected)}>
                  <Checkbox checked={option.selected} />
                  <OptionLabel>{option.label}</OptionLabel>
                </Space>
              );
            })}
          </OptionsContainer>
        </Contents>
      )}
    >
      <Button disabled={!hasOptions} style={style}>
        <Space>
          {buttonLabel}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
