import React, { PropsWithChildren } from 'react';
import { Content, Left, LeftContent, Right } from './style';
import { colors } from '../../../../theme/colors';
import { CardHeader } from '../../../../pages/onboarding/Steps/CardHeader';

interface Props {
  title?: string;
  subTitle?: string;
  image?: string;
  styles?: React.CSSProperties;
  type?: 'primary' | 'secondary';
}

export const TwoColumnLayout: React.FC<PropsWithChildren<Props>> = ({
  title,
  subTitle,
  children,
  image,
  styles = {},
  type = 'primary',
}) => {
  const background = type === 'primary' ? colors.primary[500] : colors.blue[600];
  return (
    <Content>
      <Left>
        <CardHeader
          title={title}
          subtitle={subTitle}
          subtitleStyle={{ textAlign: 'left' }}
          titleStyle={{ textAlign: 'left' }}
        />
        <LeftContent>{children}</LeftContent>
      </Left>
      <Right background={background}>
        {image ? <img src={image} style={{ height: '100%', width: '100%', ...styles }} /> : <></>}
      </Right>
    </Content>
  );
};
