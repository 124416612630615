import useAppcues from '../../features/appcues/hoc/AppcuesProvider';
import useDelighthed from '../../features/delighted/hoc/DelightedProvider';
import useHotjar from '../../features/hotjar/hoc/HotjarProvider';
import useThena from '../../features/thena/useThena';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { ManageInsightsFolderModal } from '../../features/libraries/components/ManageInsightFoldersModal';
import { useEffect } from 'react';
import { useAnalytics } from '../../features/analytics/hooks/useAnalytics';
import { useToast } from '../../hooks/useToast';

const CombinedWrappers: React.FC = () => {
  const location = useLocation();
  const { sendEvent } = useAnalytics();

  const { neutral } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('notification')) {
      neutral(searchParams.get('notification') as string);
      setSearchParams({});
    }
  });

  useEffect(() => {
    sendEvent('Page Viewed', {
      event: {
        path: location.pathname,
      },
    });
  }, [location]);

  useHotjar();
  useAppcues();
  useDelighthed();
  useThena();

  return (
    <>
      <ManageInsightsFolderModal />
      <Outlet />
    </>
  );
};

export default CombinedWrappers;
