import React from 'react';
import Section from '../_Misc/Section';
import ToggleCard from './ToggleCard';
import {
  Container,
  Divider,
  HeaderSection,
  Line,
  ToggleCardContainer,
  BotGreetingSwitch,
} from './style';
import { useUserSettings } from '../../../../features/user/hooks/useUserSettings';
import { Checkbox, Switch, Tooltip } from 'antd';
import { Alert } from '../../../../components/alerts/Alert';
import { Title } from './ToggleCard/style';
import { Tips } from './Tips';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { colors } from '../../../../theme/colors';
import { Announcement } from './Announcement';

interface Props {
  setSelectedTab: (e: string) => void;
  crmIntegrationEnabled?: boolean;
}

const Recordings: React.FC<Props> = ({ setSelectedTab, crmIntegrationEnabled }) => {
  const {
    settings,
    isProUser,
    updateInternalMeetingRecordingSetting,
    updateExternalMeetingRecordingSetting,
    updateMeetingRecordingSetting,
    updateExternalSendBot,
    updateInternalSendBot,
    updateSendBot,
    updateSettings,
  } = useUserSettings();

  const goToIntegrations = () => {
    setSelectedTab('integrations');
  };

  return (
    <Container>
      <Section title="Recording Settings">
        <div style={{ display: 'flex' }}>
          <Tips />

          <ToggleCardContainer>
            <HeaderSection>
              <Title>Select the meeting types that you’d like recorded</Title>

              <Alert
                variant="warning"
                message="Want to know how we define internal, external, and unmatched meetings?"
                title=""
                primaryAction={{
                  label: 'Learn how here',
                  onClick: () =>
                    window.open(
                      'https://help.update.ai/en/articles/8024488-how-does-updateai-know-whether-a-meeting-is-internal-or-external',
                    ),
                }}
              />
            </HeaderSection>

            <ToggleCard
              switchPosition="title"
              title={
                <div style={{ fontSize: '16px' }}>
                  Record all meetings that <span style={{ color: '#0675FC' }}>I HOST</span>
                </div>
              }
              recommendedSetting="ON (Internal) | ON (External)"
              checked={
                settings.internalMeetingsRecording.record ||
                settings.externalMeetingsRecording.record
              }
              onChange={e => {
                updateMeetingRecordingSetting(e);
              }}
            >
              <Checkbox
                onChange={e => updateInternalMeetingRecordingSetting(e.target.checked)}
                checked={settings.internalMeetingsRecording.record}
              >
                Internal meetings
              </Checkbox>
              <Checkbox
                style={{ margin: 0 }}
                onChange={e => updateExternalMeetingRecordingSetting(e.target.checked)}
                checked={settings.externalMeetingsRecording.record}
              >
                External meetings
              </Checkbox>
            </ToggleCard>

            <Divider />

            <ToggleCard
              switchPosition="title"
              title={
                <div style={{ fontSize: '16px' }}>
                  Record all meetings that I am invited to but{' '}
                  <span style={{ color: '#0675FC' }}>DO NOT HOST</span>
                </div>
              }
              recommendedSetting="ON (Internal) | ON (External) | ON (Send greeting email)"
              checked={
                settings.internalMeetingsRecording.sendBotIfNoHost ||
                settings.externalMeetingsRecording.sendBotIfNoHost
              }
              onChange={e => {
                updateSendBot(e);
              }}
            >
              <Checkbox
                onChange={e => updateInternalSendBot(e.target.checked)}
                checked={settings.internalMeetingsRecording.sendBotIfNoHost}
              >
                Internal meetings
              </Checkbox>
              <Checkbox
                style={{ margin: 0 }}
                onChange={e => updateExternalSendBot(e.target.checked)}
                checked={settings.externalMeetingsRecording.sendBotIfNoHost}
              >
                External meetings
              </Checkbox>

              <BotGreetingSwitch>
                <div>
                  Send bot greeting email to host 1 hour prior to the meeting.{' '}
                  <Tooltip
                    overlayStyle={{ maxWidth: '560px' }}
                    style={{ maxHeight: 'auto', maxWidth: 'auto' }}
                    title={
                      <img
                        style={{ maxHeight: '400px' }}
                        src="https://assets.update.ai/bot_email_sample.png"
                      />
                    }
                  >
                    <span style={{ color: colors.primary[500] }}>(Preview the email)</span>
                  </Tooltip>
                </div>

                <OptionalTooltipWrapper value="PRO plan required" display={!isProUser}>
                  <Switch
                    size="small"
                    checked={settings.sendBotGreetingEmail}
                    onChange={e => updateSettings({ sendBotGreetingEmail: e })}
                    disabled={!isProUser}
                  />
                </OptionalTooltipWrapper>
              </BotGreetingSwitch>
            </ToggleCard>

            <Divider>
              <Line></Line>
            </Divider>

            <Announcement />

            <Divider>
              <Line></Line>
            </Divider>

            <ToggleCard
              title="Auto delete the meeting recording hosted by Zoom"
              description="UpdateAI will delete your meeting recordings from Zoom so you don't run out of space. Your meeting summary will remain in UpdateAI where your space is unlimited."
              recommendedSetting="ON"
              checked={settings.deleteAfterFetching}
              onChange={e => {
                updateSettings({ deleteAfterFetching: !!e });
              }}
              learnMore="https://help.update.ai/en/articles/6649738-my-zoom-cloud-recording-is-running-out-of-space"
            />

            <Divider>
              <Line></Line>
            </Divider>

            <ToggleCard
              title="Auto push meeting recaps to my CRM"
              description="Automatically push my meeting recaps to the CRM I have connected to UpdateAI whenever an account is detected."
              recommendedSetting="ON (REQUIRES CRM INTEGRATION)"
              checked={settings.syncRecapAutomaticallyCrm}
              onChange={e => updateSettings({ syncRecapAutomaticallyCrm: !!e })}
              onClickRecommendedSetting={goToIntegrations}
              disabled={!crmIntegrationEnabled}
              tooltipTitle="Requires CRM integration"
            />
          </ToggleCardContainer>
        </div>
      </Section>
    </Container>
  );
};

export default Recordings;
