import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../services/core/core-service';
import { useSession } from '../../../hooks/useSession';

export const useCrmFilterValues = () => {
  const { user: updateUser } = useSession();

  const { data, isFetching } = useQuery({
    queryKey: ['workspace-crm-integration-filter-values', updateUser?.id],
    queryFn: () => {
      return coreService.getWorkspaceCrmFilters();
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return {
    filters: data?.filters || {},
    filterKeys: data?.filters ? Object.keys(data.filters) : [],
    isFetching,
  };
};
