import { ReactElement } from 'react';

import style from './styles';
import { Info } from '../../../../../../components/alerts/Alert/components/icons/Info';

export const OnlyOneConnected: React.FC<{ elem: ReactElement }> = ({ elem }) => {
  const { Warn } = style;
  return (
    <Warn>
      <Info style={{ scale: '0.6' }} />
      {elem}
    </Warn>
  );
};
