import React, { PropsWithChildren } from 'react';
import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Frame } from './Frame';
import { Container, LockScreen, LogOut, Logo } from './style';
import { useAnalytics } from '../../../features/analytics/hooks/useAnalytics';
import { logoutUser } from '../../../redux/slices/sessionSlice';

interface Prop {
  error?: string;
  blockLayout?: boolean;
  logOut?: boolean;
}

export const FramedLayout: React.FC<PropsWithChildren<Prop>> = ({
  children,
  error,
  blockLayout: blockPage = false,
  logOut = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reset } = useAnalytics();

  return (
    <>
      <Container>
        <Frame error={error || null} children={children} />
        <Logo
          src="/static/media/updateAI-logo.4a2adcf05b9d0ce0bd95dbc815d518e9.svg"
          onClick={() => navigate('/')}
        />
        {logOut ? (
          <LogOut>
            <Button
              onClick={() => {
                dispatch(logoutUser());
                reset();
              }}
              style={{
                backgroundColor: '#eff2f7',
                width: '120px',
              }}
              icon={<LogoutOutlined />}
            >
              Log out
            </Button>
          </LogOut>
        ) : (
          <></>
        )}
      </Container>
      <LockScreen show={blockPage} />
    </>
  );
};
