import React, { useState } from 'react';
import { Modal } from '../../../../../componentsV2/Modal';
import { Alert } from '../../../../../components/alerts/Alert';
import { Button, Input } from 'antd';
import Styles from './styles';

const { Container } = Styles;

interface Props {
  open: boolean;
  loading?: boolean;
  saveFilter(name: string): void;
  onClose: () => void;
}

export const CreateFiltersModal: React.FC<Props> = ({
  open,
  loading = false,
  onClose,
  saveFilter,
}) => {
  const [newName, setNewName] = useState<string | undefined>(undefined);

  const handleCancel = () => {
    if (!loading) {
      onClose();
    }
  };

  const labels = {
    title: 'Create new View',
    close: 'Close',
    info: 'Here you save the new View by just adding the name.',
  };

  return (
    <Modal
      width={600}
      open={open}
      secondaryAction={{
        label: labels.close,
        onClick: handleCancel,
        disabled: loading,
      }}
      title={labels.title}
      onClose={handleCancel}
    >
      <Container>
        <Alert title="" variant="info" message={labels.info} />

        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}
        >
          <Input
            onChange={n => setNewName(n.target.value)}
            style={{ maxWidth: '300px' }}
            placeholder="Name of the new Views"
            disabled={loading}
          />
          <Button
            type="primary"
            disabled={!newName || newName.length === 0}
            onClick={() => saveFilter(newName || '')}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
