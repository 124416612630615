import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Progress } from '../Progress';
import { BoundedContent, Content } from './style';
import { integrationBroadcast } from '../../../App';
import {
  selectOnboardingError,
  selectOnboardingLoading,
  selectCalendarIntegration,
} from '../../../redux/selectors';
import { setCurrentStep } from '../../../redux/slices/onboardingSlice';
import { silentFetchUser, integrateCalendar } from '../../../redux/slices/sessionSlice';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../types/commonTypes';
import { getStaticAssetURL } from '../../../utils/assets';
import { DropdownButton } from '../../../features/onboarding/components/DropdownButton';
import { FramedLayout } from '../../../components/layout/FramedLayout/FramedLayout';
import { TwoColumnLayout } from '../../../components/layout/FramedLayout/TwoColumnLayout';

const isIntegrationErrorMessage = (eventErrorMessage: string) => {
  return !eventErrorMessage || eventErrorMessage !== 'undefined';
};

export const Calendar: React.FC = () => {
  const [globalError, setGlobalError] = useState<string | undefined>(undefined);
  const [providerSelected, setProviderSelected] = useState<'google' | 'microsoft' | 'none'>('none');

  const dispatch = useDispatch();

  const [params] = useSearchParams();

  const error = useSelector(selectOnboardingError);
  const statusLoading = useSelector(selectOnboardingLoading);
  const integration = useSelector(selectCalendarIntegration);

  integrationBroadcast.onmessage = event => {
    if (event.data.source && event.data.source === 'NYLAS') {
      if (isIntegrationErrorMessage(event.data.errorMessage))
        setGlobalError(event.data.errorMessage);
      dispatch(silentFetchUser());
    }
  };

  useMemo(() => {
    if (!!integration?.email) dispatch(setCurrentStep());
  }, [integration?.email]);

  return (
    <FramedLayout
      error={error || params.get('errorMessage') || globalError || undefined}
      blockLayout={statusLoading}
      logOut
    >
      <TwoColumnLayout
        title="Sync your calendar"
        subTitle="We need access to your calendar to list your events and record your meetings; we won't edit events."
        image={'https://assets.update.ai/sync-calendar.png'}
        styles={{
          height: 'auto',
          maxHeight: '420px',
          marginLeft: '-25px',
          width: 'auto',
          marginTop: '20px',
        }}
      >
        <Content>
          <BoundedContent>
            <DropdownButton
              title="Connect Google account"
              icon={getStaticAssetURL('/google_logo.png')}
              onClick={() => {
                dispatch(integrateCalendar({ provider: 'GOOGLE' }));
                setProviderSelected('google');
              }}
              expand={providerSelected === 'google'}
            />
            <DropdownButton
              title="Connect Microsoft account"
              icon={getStaticAssetURL('/microsoft_logo.png')}
              onClick={() => {
                dispatch(integrateCalendar({ provider: 'MICROSOFT' }));
                setProviderSelected('microsoft');
              }}
              expand={providerSelected === 'microsoft'}
            />
          </BoundedContent>

          <Progress total={ONBOARDING_TOTAL_STEPS_AMOUNT} current={3} />
        </Content>
      </TwoColumnLayout>
    </FramedLayout>
  );
};
