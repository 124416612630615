import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOnboardingError, selectOnboardingLoading } from '../../../redux/selectors';
import { setCurrentStep } from '../../../redux/slices/onboardingSlice';
import { Container, Content, NavigationButtons, Setting, SettingTitle, Status } from './style';
import { CardHeader } from './CardHeader';
import { Button, Switch, Tooltip } from 'antd';
import { ONBOARDING_TOTAL_STEPS_AMOUNT } from '../../../types/commonTypes';
import { Progress } from '../Progress';
import { updateMeetingRecordingSetting, updateSendBot } from '../../../features/user/user.service';
import { OptionalTooltipWrapper } from '../../../componentsV2/OptionalTooltipWrapper';
import { FramedLayout } from '../../../components/layout/FramedLayout/FramedLayout';

export const RecordingSettings: React.FC = () => {
  const dispatch = useDispatch();

  const [settings, setSettings] = useState({ internal: false, external: false });

  const setInternal = async (value: boolean) => {
    setSettings(s => ({ ...s, internal: value }));
    updateMeetingRecordingSetting(value).catch(() =>
      setSettings(s => ({ ...s, internal: !value })),
    );
  };

  const setExternal = async (value: boolean) => {
    setSettings(s => ({ ...s, external: value }));
    updateSendBot(value).catch(() => setSettings(s => ({ ...s, external: !value })));
  };

  useEffect(() => {
    setInternal(true);
    setExternal(true);
  }, []);

  const toggles = useMemo(() => {
    return (
      <>
        <Setting>
          <SettingTitle>
            <Tooltip title="Internal meeting = everyone on the meeting invitation shares your email domain.">
              <h1>All internal meetings</h1>
            </Tooltip>
            <h2>Recommended: ON</h2>
          </SettingTitle>
          <Switch checked={settings.internal} onChange={e => setInternal(e)} />
        </Setting>

        <Setting>
          <SettingTitle>
            <Tooltip title="External meeting = one or more of the invitees on the meeting invitation has an email domain that is different from your own.">
              <h1>All external meetings</h1>
            </Tooltip>
            <h2>Recommended: ON</h2>
          </SettingTitle>
          <Switch checked={settings.external} onChange={e => setExternal(e)} />
        </Setting>
      </>
    );
  }, [settings.internal, settings.external]);

  const error = useSelector(selectOnboardingError);
  const loading = useSelector(selectOnboardingLoading);
  const displayTooltip = !settings.internal && !settings.external;

  const onNext = async () => {
    dispatch(setCurrentStep());
  };

  return (
    <FramedLayout error={error} blockLayout={loading} logOut>
      <Container>
        <CardHeader
          title="Select the meeting types that you’d like recorded"
          subtitle="You can always change this later and adjust it per meeting."
        />

        <Content style={{ alignItems: 'center', height: '130px', margin: 0 }}>{toggles}</Content>

        <Status>
          <NavigationButtons>
            <div />
            <OptionalTooltipWrapper
              display={displayTooltip}
              value="One or more meeting types must be enabled to proceed"
            >
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  onNext();
                }}
                disabled={displayTooltip}
              >
                Explore a demo recap now!
              </Button>
            </OptionalTooltipWrapper>
          </NavigationButtons>

          <Progress total={ONBOARDING_TOTAL_STEPS_AMOUNT} current={5} />
        </Status>
      </Container>
    </FramedLayout>
  );
};
