import React from 'react';
import Styles from './styles';
import { Alert } from '../../../../../../components/alerts/Alert';
import { CopyFilled } from '@ant-design/icons';
import { useToast } from '../../../../../../hooks/useToast';

const { Label, Container, Data, CopyKey } = Styles;

interface Props {
  id: string;
  secret?: string;
}

export const MoreInfo: React.FC<Props> = ({ id, secret }) => {
  const { success } = useToast();

  const clickableSecret = () => {
    const component = <Label>Secret Key: {secret}</Label>;

    if (secret?.includes('***')) return component;

    return (
      <Data>
        {component}
        <CopyKey
          onClick={() => {
            navigator.clipboard.writeText(secret || '');
            success('Copied to clipboard');
          }}
        >
          <CopyFilled />
          Copy Key
        </CopyKey>
      </Data>
    );
  };

  return (
    <Container>
      <Label>Generated Key</Label>

      <Data>
        <Label>ID: {id} </Label>
        {clickableSecret()}
      </Data>

      {secret && (
        <div
          style={{
            marginTop: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Alert
            variant="warning"
            padding="8px"
            title=""
            width="430px"
            message="Be sure to copy and save the Secret Key, after that it will be hidden and if you forget it you will die!  JK, but you will have to generate a new one."
          />
        </div>
      )}
    </Container>
  );
};
