import React, { ReactNode, useEffect, createContext } from 'react';
import { insightCategoriesStore, reducers } from '../../stores/insights-categories';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { GetInsightCategoriesResponse } from '../../../../services/types';

interface Props {
  children: ReactNode;
  insightType: string;
}

interface InsightCategoriesContext {
  refetch: () => unknown;
}

export const insightCategoriesContext = createContext<InsightCategoriesContext>({
  refetch: () => null,
});

const sortCategoriesResponse = (response: GetInsightCategoriesResponse) => {
  const categories = response.categories.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  );
  return categories;
};

export const InsightCategoriesProvider: React.FC<Props> = ({ children, insightType }) => {
  const { type } = insightCategoriesStore();

  const { isFetching, data, refetch } = useQuery({
    queryKey: [type],
    queryFn: () => {
      return coreService.getInsightCategories(type);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    reducers.fetchStatusChanged(isFetching);
  }, [isFetching]);

  useEffect(() => {
    reducers.typeChanged(insightType);
  }, [insightType]);

  useEffect(() => {
    if (!data) {
      reducers.categoriesFetched([]);
      return;
    }

    const sortedCategories = sortCategoriesResponse(data).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    const mutable = sortedCategories.filter(category => category.mutable);
    const immutable = sortedCategories.filter(category => !category.mutable);
    reducers.categoriesFetched([...immutable, ...mutable]);
  }, [data]);

  return (
    <insightCategoriesContext.Provider value={{ refetch }}>
      {children}
    </insightCategoriesContext.Provider>
  );
};
