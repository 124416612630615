import { addDays, format, isAfter } from 'date-fns';

const formatInsightTime = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'EEE, MMM dd @ hh:mm aa');

const formatInsightTimeWithYear = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'EEE, MMM dd yyyy @ hh:mm aa');

const formatLinkTime = (date: string | Date) =>
  format(typeof date === 'string' ? new Date(date) : date, 'MMMM dd, yyyy');

export const setToZeroSeconds = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

export const setToLastSecond = (date: Date) => {
  date.setHours(23, 59, 59, 999);
  return date;
};

const sortDates = (args: [Date, Date]): [Date, Date] => {
  const [firstDate, secondDate] = args;
  if (isAfter(firstDate, secondDate)) {
    return [secondDate, firstDate];
  }

  return [firstDate, secondDate];
};

export const generateIntervalFromToday = (days: number): [Date, Date] => {
  const lower = new Date();
  const upper = addDays(lower, days);
  const [firstDate, secondDate] = sortDates([lower, upper]);
  return [setToZeroSeconds(firstDate), setToLastSecond(secondDate)];
};

const stripMils = (timeString: string) => {
  const time = timeString.split('.');
  return `${time[0]}Z`;
};

const secondsToHms = (d: number) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '00:';
  const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : '00:';
  const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
  return hDisplay + mDisplay + sDisplay;
};

export const secondsToTime = (timestamp: number | string) => {
  const time = Number.parseFloat(timestamp.toString());
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) - hours * 60;
  const seconds = Math.floor(time % 60);

  let formattedTime = '';

  if (hours !== 0) {
    formattedTime = hours.toString().padStart(2, '0') + ':';
  }

  formattedTime =
    formattedTime + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');

  return formattedTime;
};

export {
  secondsToHms,
  sortDates,
  formatInsightTime,
  stripMils,
  formatLinkTime,
  formatInsightTimeWithYear,
};
