import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { dashboardFilterContext } from './dashboard-filters.context';
import { SelectAll } from '../../../pages/insights/components/SelectAll';
import { InputNumber, Select } from 'antd';
import { DateSelector } from '../components/DateSelector';
// import { AccountFilterRender } from '../../../pages/insights/components/AccountFilter/AccountFilterRender';
// import { ProviderIntegration } from '../../../redux/typings/store';
import { DateRangeIntervals } from './types';
import { InputNumberRange } from '../../../pages/insights/components/InputNumberRange';
import { MeetingTypeFilter } from '../../home/components/MeetingTypeFilter';
import { OptionalTooltipWrapper } from '../../../componentsV2/OptionalTooltipWrapper';
import { AccountSelector } from '../../../pages/accounts2/components/AccountSelector';

export const useDashboardFilters = () => {
  const { store } = useContext(dashboardFilterContext);
  const storeData = store();

  const renderedFilters = useMemo(() => {
    const renders: { [k: string]: ReactElement } = {};

    Object.keys(storeData.filters).forEach(storeKey => {
      const filter = storeData.filters[storeKey];
      if (filter) {
        if (filter.type === 'list') {
          renders[storeKey] = (
            <SelectAll
              showAll
              style={filter.componentProps?.style}
              label={filter.componentProps?.label || ''}
              value={filter.value.options.filter(l => l.selected).map(l => l.id)}
              options={[...filter.value.options]}
              onSelectionChanged={(values: string[]) => {
                storeData.listFilterSelectionChanged(storeKey, values);
              }}
            />
          );
        }

        if (filter.type === 'select') {
          renders[storeKey] = (
            <Select
              placeholder={filter.componentProps?.label}
              style={filter.componentProps?.style}
              value={filter.value.selected}
              defaultValue={filter.value.defaultValue}
              onSelect={(values: number | string) => {
                storeData.selectFilterSelectionChanged(storeKey, values);
              }}
            >
              {filter.value.options.map(({ value, label, tooltip }, key) => (
                <Select.Option value={value} key={key}>
                  <OptionalTooltipWrapper display={!!tooltip} value={tooltip}>
                    {label}
                  </OptionalTooltipWrapper>
                </Select.Option>
              ))}
            </Select>
            // options={[...filter.value.options]}
          );
        }

        if (filter.type === 'number') {
          renders[storeKey] = <InputNumber />;
        }

        if (filter.type === 'account') {
          const key = storeData.filters[storeKey];
          const accountId = key.type === 'account' ? key.value.selected || '' : '';
          renders[storeKey] = (
            <AccountSelector
              onSelect={() => null}
              value={accountId}
              onSelectionClear={() => {
                storeData.accountFilterSelectionChanged(storeKey, undefined);
              }}
              onSelectComplete={acc => {
                storeData.accountFilterSelectionChanged(storeKey, acc.id);
              }}
            />
          );
        }

        if (filter.type === 'numberRange') {
          renders[storeKey] = (
            <InputNumberRange
              style={filter.componentProps?.style}
              range={filter.value.interval}
              label={filter.componentProps?.label || ''}
              onChange={range => storeData.rangeNumberFilterValueChanged(storeKey, range)}
            />
          );
        }

        if (filter.type === 'dateRange') {
          renders[storeKey] = (
            <DateSelector
              defaultValues={filter.value.interval}
              value={filter.value.value}
              direction="past"
              predefinedRanges={{
                today: false,
                '1d': false,
                '7d': false,
                '12m': false,
                '30d': true,
                '60d': true,
                '90d': true,
                '120d': true,
                '3m': false,
                '6m': false,
              }}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onDateOptionChanged={option => null}
              onDateRangeChanged={e => {
                storeData.dateFilterSelectionChanged(storeKey, [
                  new Date(e[0] || ''),
                  new Date(e[1] || ''),
                ]);
              }}
            />
          );
        }

        if (filter.type === 'meetingType') {
          renders[storeKey] = (
            <MeetingTypeFilter
              style={filter.componentProps?.style}
              showTitle={false}
              flex={false}
              initialType={null}
              showAll
              size="middle"
              onChange={t => storeData.meetingTypeChanged(storeKey, t)}
            />
          );
        }
      }
    });

    return renders;
  }, [storeData.filters]);

  const render = (key: string) => {
    return renderedFilters[key];
  };

  const getListFilterOptions = useCallback(
    (k: string) => {
      const data = storeData.filters[k];
      if (data && data.type === 'list') {
        return data.value.options;
      }
      return [];
    },
    [storeData.filters],
  );

  const getDateFilterOptions = useCallback(
    (k: string): DateRangeIntervals => {
      const data = storeData.filters[k];
      if (data && data.type === 'dateRange') {
        return data.value.interval;
      }
      return [null, null];
    },
    [storeData.filters],
  );

  const getSelectFilterOptions = useCallback(
    (k: string): number | string => {
      const data = storeData.filters[k];
      if (data && data.type === 'select') {
        return data.value.selected || -1;
      }
      return -1;
    },
    [storeData.filters],
  );

  const getNumberRangeFilterOptions = useCallback(
    (k: string): [number, number] => {
      const data = storeData.filters[k];
      if (data && data.type === 'numberRange') {
        return data.value.interval;
      }
      return [0, 0];
    },
    [storeData.filters],
  );

  const getAccountFilterOptions = useCallback(
    (k: string): string | undefined => {
      const data = storeData.filters[k];
      if (data && data.type === 'account') {
        return data.value?.selected;
      }
      return undefined;
    },
    [storeData.filters],
  );

  return {
    render,
    changeAccountValue: storeData.accountFilterSelectionChanged,
    getListFilterOptions,
    getDateFilterOptions,
    getAccountFilterOptions,
    getSelectFilterOptions,
    getNumberRangeFilterOptions,
    filters: storeData.filters,
  };
};
