import React, { useEffect } from 'react';
import { useAlert } from '../../hooks/useAlert';
import { useSelector } from 'react-redux';
import { selectCalendarIntegration, selectUserSubscription } from '../../../../redux/selectors';
import { selectZoomIntegration } from '../../../../redux/selectors';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../../../../components/alerts/Alert';

export const AlertConsumer = () => {
  const { alert, addAlert } = useAlert();
  const calendarIntegration = useSelector(selectCalendarIntegration);
  const zoomIntegration = useSelector(selectZoomIntegration);
  const userSubscription = useSelector(selectUserSubscription);
  const navigate = useNavigate();

  useEffect(() => {
    if (addAlert) {
      if (!calendarIntegration?.id)
        addAlert(
          <Alert
            variant="warning"
            message="Without your calendar integration you may lose access to shared meetings with your teammates. Also, we won't be able to generate every part of the Smart Summary (e.g. Risks)."
            title="Calendar not connected"
            secondaryAction={{
              label: 'Learn more',
              onClick: () => window.open('https://help.update.ai/en/?q=calendar'),
            }}
            primaryAction={{
              label: 'Connect Calendar',
              onClick: () => navigate('/settings?tab=integrations'),
            }}
          />,
        );
      /*
      if (!zoomIntegration?.id)
        addAlert(
          <Alert
            variant="warning"
            message="Without Zoom integration we won't be able to generate Smart Summaries."
            title="Zoom not connected"
            secondaryAction={{
              label: 'Learn more',
              onClick: () => window.open('https://help.update.ai/en/?q=zoom'),
            }}
            primaryAction={{
              label: 'Connect Zoom',
              onClick: () => navigate('/settings?tab=integrations'),
            }}
          />,
        );
        */
      if (userSubscription?.type === 'trial')
        addAlert(
          <Alert
            variant="warning"
            message={`${
              userSubscription.remainingDays || 0
            } days are remaining in your trial upgrade to UpdateAI. Adjust your current plan in billing settings.`}
            title="Your trial will be over soon!"
            secondaryAction={{
              label: 'Here’s why to go pro',
              onClick: () => window.open('https://update.ai/pricing'),
            }}
            primaryAction={{
              label: 'Upgrade Plan',
              onClick: () => navigate('/settings?tab=plan-and-billing'),
            }}
          />,
        );
      if (userSubscription?.type === 'basic')
        addAlert(
          <Alert
            variant="warning"
            message={`(${
              userSubscription.remainingRecaps || 0
            }) Smart Summary™ recaps remain in your basic plan this week`}
            title="Upgrade to PRO to get unlimited Smart Summary™ recaps"
            primaryAction={{
              label: 'Upgrade Plan',
              onClick: () => navigate('/settings?tab=plan-and-billing'),
            }}
          />,
        );
    }
  }, [calendarIntegration, zoomIntegration, userSubscription]);

  return alert || null;
};
