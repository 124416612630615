import { Modal } from 'antd';
import React from 'react';
import {
  AlertLogo,
  CancelButton,
  ConfirmDeleteButton,
  Container,
  Content,
  Detail,
  Footer,
  Heading,
  TextContainer,
} from './style';
import IconSvg from '../../common/IconSvg';

interface Props {
  open?: boolean;
  onCloseModal: () => void;
  onConfirm?: () => void;
  title?: string;
  description?: string;
  loading?: boolean;
  confirmButtonText: string;
}

const ConfirmationModal: React.FC<Props> = ({
  open,
  onCloseModal,
  onConfirm,
  title,
  description,
  loading,
  confirmButtonText,
}) => {
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  const handleClose = () => {
    onCloseModal();
  };

  return (
    <Modal title={false} open={open} closable={false} destroyOnClose footer={false} centered>
      <Container>
        <Content>
          <AlertLogo>
            <IconSvg name="alertTriangle" color="transparent" size="lg" />
          </AlertLogo>
          <TextContainer>
            <Heading>{title}</Heading>
            <Detail>{description}</Detail>
          </TextContainer>
        </Content>
        <Footer>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
          <ConfirmDeleteButton
            onClick={handleConfirm}
            loading={loading}
            style={{ width: 'fit-content' }}
          >
            {confirmButtonText}
          </ConfirmDeleteButton>
        </Footer>
      </Container>
    </Modal>
  );
};

export default ConfirmationModal;
