import React, { useMemo, useState } from 'react';
import { DateSelector } from '../../../../features/shared/components/DateSelector';
import { Tag, Tooltip } from 'antd';
import { StoredFilterType } from '../StoredFilters/types';
import { useCustomerIntelligenceFilters } from '../../../../features/insights/hooks/useCustomerIntelligenceFilters';
import { useInsightsAnalytics } from '../../../../features/insights/hooks/useInsightsAnalytics';
import { AccountFilter } from '../AccountFilter';
import { CRMFilters } from './CRMFilters';
import Styles from '../../styles';
const { FiltersGroup, Header, HeaderRow } = Styles;

export const Filters: React.FC = () => {
  const { dateRange, resetFilters, changeDateRange } = useCustomerIntelligenceFilters();
  const [selectedFilter, setSelectedFilter] = useState<StoredFilterType | undefined>(undefined);

  const { portfolio } = useInsightsAnalytics();

  const SelectedFitlerTag = useMemo(() => {
    return selectedFilter ? (
      <Tag
        style={{
          height: 'auto',
          fontSize: '13px',
          paddingLeft: '10px',
          paddingRight: '10px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '8px',
        }}
        closeIcon
        onClose={() => {
          resetFilters();
          setSelectedFilter(undefined);
          portfolio.filtered('reset filters');
        }}
      >
        <Tooltip title={selectedFilter.name}>
          <div
            style={{
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {selectedFilter.name}
          </div>
        </Tooltip>
      </Tag>
    ) : (
      <></>
    );
  }, [selectedFilter?.name]);

  return (
    <Header $visible={true}>
      <HeaderRow>
        <FiltersGroup>
          <DateSelector
            defaultValues={dateRange}
            value="30d"
            direction="past"
            predefinedRanges={{
              today: false,
              '1d': false,
              '7d': true,
              '30d': true,
              '3m': false,
              '6m': true,
              '12m': false,
            }}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onDateOptionChanged={option => null}
            onDateRangeChanged={e => {
              changeDateRange(e);
            }}
          />

          <AccountFilter />

          <CRMFilters />
        </FiltersGroup>
      </HeaderRow>

      <HeaderRow>
        <FiltersGroup>{SelectedFitlerTag}</FiltersGroup>
      </HeaderRow>
    </Header>
  );
};
