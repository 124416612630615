import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../componentsV2/Modal';
import { Alert } from '../../../../components/alerts/Alert';
import { Input } from 'antd';
import { format } from 'date-fns';
import { coreAPI } from '../../../../API/core';
import { useToast } from '../../../../hooks/useToast';

import Styles from './styles';
const { Container, MeetingDetails, MeetingDate, MeetingTitle } = Styles;

interface Props {
  open: boolean;
  event: {
    id: string;
    title: string;
    scheduledStartDate: Date;
    purposeStatement: string | null;
  } | null;
  onClose: () => unknown;
  onPurposeChanged: (id: string, statement: string) => unknown;
}

export const PruposeStatementModal: React.FC<Props> = ({
  onClose,
  open,
  event,
  onPurposeChanged,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [purposeStatement, setPurposeStatement] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  const { error, success } = useToast();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (event) {
      setPurposeStatement(event.purposeStatement || '');
    }
  }, [event]);

  if (!event) {
    return null;
  }

  const handleClose = () => {
    setIsOpen(false);
    setPurposeStatement('');
    onClose();
  };

  const handleSave = () => {
    if (purposeStatement) {
      setIsSaving(true);
      const api = new coreAPI();
      api
        .updateEvent({
          id: event.id,
          purposeStatement: purposeStatement,
        })
        .then(() => {
          onPurposeChanged(event.id, purposeStatement);
          success('Purpose statement saved');
        })
        .catch(() => error('Failed to save purpose statement'))
        .finally(() => {
          setIsSaving(false);
          handleClose();
        });
    }
  };

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      title="Purpose Statement"
      primaryAction={{
        label: 'Save',
        loading: isSaving,
        onClick: handleSave,
      }}
      secondaryAction={{ label: 'Cancel', onClick: handleClose }}
    >
      <Container>
        <Alert variant="info" title="" message="The purpose statement improves our AI detections" />
        <MeetingDetails>
          <MeetingTitle>Title: {event.title}</MeetingTitle>
          <MeetingDate>
            Date: {format(event.scheduledStartDate, 'eeee, MMMM d, yyyy @ h:mma')}
          </MeetingDate>
        </MeetingDetails>
        <Input.TextArea
          value={purposeStatement}
          onChange={ev => setPurposeStatement(ev.target.value)}
          placeholder="The purpose of this meeting is..."
        />
      </Container>
    </Modal>
  );
};
