import React, { useState } from 'react';
import { useToast } from '../../../../../hooks/useToast';
import { coreService } from '../../../../../services/core/core-service';
import { extractError } from '../../../../../utils/api';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

interface Props {
  onOrganizationTransfered: () => unknown;
  onTransferCanceled: () => unknown;
  user: {
    id: string;
    email: string;
    fullName: string;
  } | null;
}

const TransferOwnershipModal: React.FC<Props> = ({
  onOrganizationTransfered,
  user,
  onTransferCanceled,
}) => {
  const { success, error } = useToast();
  const [isTransfering, setIsTransfering] = useState(false);
  const open = Boolean(user);

  const handleTransfer = async () => {
    if (!user) {
      return;
    }
    setIsTransfering(true);
    try {
      await coreService.transferOrganization(user.id);
      success(`Workspace transfered successfully.`);
      onOrganizationTransfered();
    } catch (e) {
      const errorMessage = extractError(e);
      error(errorMessage);
    } finally {
      setIsTransfering(false);
    }
  };

  const handleTransferCancel = () => {
    onTransferCanceled();
  };

  return (
    <ConfirmationModal
      confirmButtonText={'Yes, transfer it now'}
      onCloseModal={handleTransferCancel}
      onConfirm={handleTransfer}
      loading={isTransfering}
      open={open}
      description={
        'Are you sure you want to transfer the ownership of your workspace? By doing this, you will no longer be the owner of your workspace and this action cannot be undone. '
      }
      title={'Transfer ownership'}
    ></ConfirmationModal>
  );
};

export default TransferOwnershipModal;
