import React, { useEffect, useState } from 'react';
import { useSummarizeInsights } from '../../summarize-insights.hook';
import { Modal } from '../../../../../componentsV2/Modal';
import Styles from './styles';
import { Alert } from '../../../../../components/alerts/Alert';
import { Button, Input, Skeleton, Tag } from 'antd';
import { useToast } from '../../../../../hooks/useToast';
import { useSummarizeInsightsAnalytics } from '../../hooks/useSummarizeInsightsAnalytics';

const { Container, Footer, SummaryContainer, PoweredBy, SummaryTextContainer } = Styles;

export const ShareSmartSummaryModal: React.FC = () => {
  const { show, close, isSummarizing, resultAsText, additionalNote, additionalNoteModified } =
    useSummarizeInsights();
  const { summaryGenerated, noteAdded, textCopied } = useSummarizeInsightsAnalytics();
  const [showAddNote, setShowAddNote] = useState(false);
  const { success } = useToast();

  useEffect(() => {
    if (isSummarizing) {
      summaryGenerated();
    }
  }, [isSummarizing]);

  const handleCopy = () => {
    if (resultAsText) {
      navigator.clipboard.writeText(resultAsText);
      success('Share Summary copied to clipboard');
      textCopied();
    }
  };

  const handleAdditionalNoteChanged = (value: string) => {
    additionalNoteModified(value);
  };

  const handleClose = () => {
    setShowAddNote(false);
    close();
  };

  const handleAddNote = () => {
    setShowAddNote(true);
    noteAdded();
  };

  return (
    <Modal
      width={700}
      title="Share Wizard"
      onClose={handleClose}
      open={show}
      secondaryAction={{ label: 'Cancel', onClick: handleClose }}
    >
      <Container>
        <Alert
          emoji="🙌"
          padding="0"
          title=""
          message="We're generating a customizable AI summary of key moments to easily copy, paste, and share with your colleagues."
          variant="info"
        />
        <SummaryContainer>
          <span>
            <Tag
              style={{
                backgroundColor: '#7230FF',
                color: '#FAFBFD',
                borderRadius: '4px',
                border: 'none',
              }}
            >
              Share Summary
            </Tag>
          </span>
          {isSummarizing ? (
            <Skeleton active />
          ) : resultAsText ? (
            <SummaryTextContainer>
              <p style={{ whiteSpace: 'pre-wrap' }}>{resultAsText}</p>
            </SummaryTextContainer>
          ) : (
            'No summary'
          )}
          {showAddNote && (
            <Input.TextArea
              onChange={ev => handleAdditionalNoteChanged(ev.target.value)}
              value={additionalNote}
              placeholder="Write a note"
            ></Input.TextArea>
          )}
          <PoweredBy>Powered by www.update.ai</PoweredBy>
        </SummaryContainer>
        <Footer>
          <Button type="default" onClick={handleAddNote}>
            Add Note
          </Button>
          <Button type="default" onClick={handleCopy}>
            Copy Text
          </Button>
        </Footer>
      </Container>
    </Modal>
  );
};
