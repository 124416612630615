import { Button, Input, Tooltip } from 'antd';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { TranscriptLine } from '../TranscriptLine';
import { NoMessagesFoundContainer, MessagesContainer } from './styles';
import { DownloadOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';
import { Utterance } from '../../../recapv2/recap-management/types';
import { useRecapPlayback } from '../../../recapv2/recap-playback/hook';
import Mark from 'mark.js';

import Styles from './styles';
import { secondsToTime } from '../../../../utils/dateUtils';

const { AutoscrollContainer, AutoscrollButton, TranscriptContainer } = Styles;
const { Search } = Input;

export const Transcript: React.FC = () => {
  const { transcript, permissions, title } = useRecapManagement();
  const { messageIdPlaying, isPlaying, autoScroll, changeAutoScroll } = useRecapPlayback();

  const [inputValue, setInputValue] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0, width: 0 });
  const markRef = useRef<Mark | null>(null);

  const scrollToSelectedMessage = () => {
    if (autoScroll && containerRef.current) {
      const element = document.getElementById(`transcript-row-${messageIdPlaying}`);
      if (element) {
        const containerBounds = containerRef.current.getBoundingClientRect();
        const elementBounds = element.getBoundingClientRect();
        const amountAlreadyScrolled = containerRef.current.scrollTop;
        const nextScrollPosition =
          elementBounds.top -
          containerBounds.top +
          amountAlreadyScrolled -
          containerBounds.height / 2 +
          elementBounds.height / 2;
        containerRef.current.scrollTo({
          top: nextScrollPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  const updatePosition = () => {
    if (containerRef.current) {
      const top =
        containerRef.current.getBoundingClientRect().top + containerRef.current.clientHeight - 96;
      const left = containerRef.current.getBoundingClientRect().left;
      const width = containerRef.current.clientWidth;
      setPosition({ top, left, width });
    }
  };

  const downloadTranscript = () => {
    const a = document.createElement('a');
    const blob = new Blob(
      transcript.map(p => {
        return `[${secondsToTime(p.startTime)}] ${p.speaker}:\n${p.content}\n\n`;
      }),
      { type: 'text/plain' },
    );
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', title.toLocaleLowerCase().split(' ').join('-') + '.txt');
    a.click();
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchDebounce, _] = useDebounce(inputValue, 300);
  const sanitizeSearchDebounce = (): [string, RegExp] => {
    const cleanSearch = searchDebounce.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    return [cleanSearch, new RegExp(`(${cleanSearch})`, 'i')];
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchString = e.currentTarget.value;
    setInputValue(searchString);
  };

  const transcriptContent = useMemo(() => {
    const [search, regex] = sanitizeSearchDebounce();
    let filteredMessages: Utterance[] = [];

    if (search === '') {
      filteredMessages = transcript;
    } else {
      filteredMessages = transcript.filter(msg => msg.content.search(regex) !== -1);
    }

    if (!filteredMessages.length && search !== '') {
      return <NoMessagesFoundContainer>No matches for "{searchDebounce}"</NoMessagesFoundContainer>;
    }

    return filteredMessages.map(msg => {
      return (
        <TranscriptLine
          key={msg.id}
          id={msg.id}
          chunks={msg.segments}
          speaker={msg.speaker}
          content={msg.content}
          timestamp={String(msg.startTime)}
        />
      );
    });
  }, [transcript, searchDebounce]);

  const showScrolling = isPlaying;

  useEffect(() => {
    scrollToSelectedMessage();
  }, [messageIdPlaying]);

  useEffect(() => {
    if (autoScroll) {
      scrollToSelectedMessage();
    }
  }, [autoScroll]);

  useEffect(() => {
    const handleEvent = () => {
      updatePosition();
    };

    if (containerRef.current) {
      updatePosition();
      window.addEventListener('resize', handleEvent);
      containerRef.current.addEventListener('resize', handleEvent);
    }

    return () => {
      window.removeEventListener('resize', handleEvent);
      if (containerRef.current) {
        containerRef.current.removeEventListener('resize', handleEvent);
      }
    };
  }, [containerRef.current]);

  useEffect(() => {
    if (markRef.current) {
      markRef.current.unmark();
    }

    const [search, regex] = sanitizeSearchDebounce();
    if (search.length !== 0) {
      const context = document.querySelectorAll(
        '[id^="transcript-row-"]',
      ) as unknown as HTMLElement[];
      const mark = new Mark(context);
      markRef.current = mark;
      mark.markRegExp(regex);
    }
  }, [transcript, searchDebounce]);

  return (
    <TranscriptContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px 16px 12px 16px',
          gap: '10px',
        }}
      >
        <Search placeholder="Search transcript" value={inputValue} onChange={onSearchChange} />

        {permissions.canManageShareLinks && (
          <Tooltip title="Download transcript">
            <Button
              icon={<DownloadOutlined />}
              style={{ width: '40px' }}
              onClick={downloadTranscript}
            />
          </Tooltip>
        )}
      </div>
      <MessagesContainer ref={containerRef}>
        {transcriptContent}
        {showScrolling && (
          <AutoscrollContainer
            style={{ top: position.top, left: position.left, width: position.width }}
          >
            <AutoscrollButton
              type="primary"
              icon={autoScroll ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
              onClick={() => {
                changeAutoScroll(!autoScroll);
              }}
            >
              {autoScroll ? 'Pause' : 'Resume'} Auto-scrolling
            </AutoscrollButton>
          </AutoscrollContainer>
        )}
      </MessagesContainer>
    </TranscriptContainer>
  );
};
