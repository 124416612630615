import React, { useEffect, useMemo, useState } from 'react';
import JoinOrganization from './JoinOrganization';
import CreateOrganization from './CreateOrganization';
import Style from './style';
import { useDispatch, useSelector } from 'react-redux';
import { coreAPI } from '../../../../API/core';
import { selectOnboardingError, selectOnboardingLoading } from '../../../../redux/selectors';
import { setCurrentStep } from '../../../../redux/slices/onboardingSlice';
import { Invitation } from '../../../../redux/typings/organizations';
import { FramedLayout } from '../../../../components/layout/FramedLayout/FramedLayout';

const { Container } = Style;
const api = new coreAPI();

export const Organization: React.FC = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectOnboardingError);
  const loading = useSelector(selectOnboardingLoading);

  const [orgs, setOrgs] = useState<Invitation[]>([]);
  const [isLoadingOrganizations, setIsloadingOrganizations] = useState(false);
  const [currentScreen, setCurrentScreen] = useState<'create' | 'join'>('join');

  useEffect(() => {
    setIsloadingOrganizations(true);
    api
      .fetchJoinableOrganizations()
      .then(data => {
        setOrgs(data);
        if (data.length === 0) {
          setCurrentScreen('create');
        } else {
          setCurrentScreen('join');
        }
      })
      .finally(() => setIsloadingOrganizations(false));
  }, []);

  const pageToShow = useMemo(() => {
    if (currentScreen === 'create') {
      return (
        <CreateOrganization
          onOrganizationCreated={() => dispatch(setCurrentStep())}
          onBack={() => setCurrentScreen('join')}
          showBack={orgs.length > 0}
        />
      );
    }

    if (currentScreen === 'join') {
      return (
        <JoinOrganization
          onCreateOrganization={() => setCurrentScreen('create')}
          onJoined={() => dispatch(setCurrentStep())}
          invitations={orgs}
          loading={isLoadingOrganizations}
        />
      );
    }
  }, [currentScreen, orgs, isLoadingOrganizations]);

  return (
    <FramedLayout error={error} blockLayout={loading} logOut>
      <Container>{pageToShow}</Container>
    </FramedLayout>
  );
};
