import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Styles from './styles';
import { Alert } from '../../../components/alerts/Alert';

const { Container, LeftsideContainer, RightsideContainer, Logo, Fingerprint, AlertContainer } =
  Styles;

interface Props {
  leftSide: React.ReactElement;
  rightSide: React.ReactElement;
}

const AuthenticationLayout: React.FC<Props> = ({ leftSide, rightSide }) => {
  const [params] = useSearchParams();

  const errorMessage = params.get('errorMessage');

  return (
    <Container>
      <LeftsideContainer>
        <Logo src="/static/media/updateAI-logo.4a2adcf05b9d0ce0bd95dbc815d518e9.svg" />
        {errorMessage && (
          <AlertContainer>
            <Alert message={errorMessage} title="Error" variant="error" />
          </AlertContainer>
        )}
        {leftSide}
      </LeftsideContainer>
      <RightsideContainer>
        {rightSide}
        <Fingerprint src="https://assets.update.ai/fingerprint.png" />
      </RightsideContainer>
    </Container>
  );
};

export default AuthenticationLayout;
